<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title>Chart of Accounts</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-card-text>
        <v-row>
          <!-- list accounts -->
          <v-col cols="12" sm="2" md="2">
            <v-card
              tile
              elevation="2"
              v-for="chart in chartData"
              v-bind:key="chart.id"
              class="mb-1"
              color="#BDBDBD"
              style="cursor: pointer"
              @click="getAccounts(chart.id)"
            >
              <v-card-text>
                {{ chart.AcctName }}
              </v-card-text>
            </v-card>
          </v-col>
          <!-- end of accounts listing -->

          <!-- sec two -->
          <v-col cols="5" v-if="accountData.length > 0">
            <v-text-field
              v-model="search"
              dense
              outlined
              label="Search Chart Of Accounts"
            ></v-text-field>
       
              <v-treeview
                v-model="chartTree"
                color="accent"
                open-all
                return-object
                :hoverable="trueValue"
                :activatable="trueValue"
                item-text="AcctName"
                item-children="children"
                :items="accountData"
                item-key="id"
                :search="search"
              >
                <template v-slot:label="{ item }">
                  <div @click="selectItem(item)">
                    <span style="font-weight: bold">{{ item.AcctCode }}</span>
                    -- {{ item.AcctName }}
                  </div>
                </template>
              </v-treeview>
           
          </v-col>
          <!-- end -->

          <!-- sec three -->
          <v-col cols="5">
            <h4>G/L Account Details</h4>
            <v-text-field
              class="mt-2"
              outlined
              dense
              label="G/L Account"
              v-model="record.AcctCode"
            ></v-text-field>

            <v-text-field
              class="mt-2"
              outlined
              dense
              label="Account Name"
              v-model="record.AcctName"
            ></v-text-field>

            <v-row>
              <v-col cols="6">
                <v-autocomplete
                  class="mt-2"
                  outlined
                  dense
                  v-model="record.level"
                  :items="levels"
                  item-text="id"
                  item-value="id"
                  label="Level"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  class="mt-2"
                  outlined
                  dense
                  label="Balance"
                  v-model="record.CurrTotal"
                ></v-text-field>
              </v-col>
            </v-row>

            <h4>G/L Account Location</h4>

            <v-text-field
              class="mt-2"
              outlined
              dense
              label="Drawer"
              v-model="drawerAccount"
              readonly
            ></v-text-field>

            <v-autocomplete
              class="mt-2"
              outlined
              dense
              v-model="record.level"
              :items="levels"
              item-text="id"
              item-value="id"
              label="Parent Article"
            ></v-autocomplete>

            <v-autocomplete
              class="mt-2"
              outlined
              dense
              v-model="record.level"
              :items="levels"
              item-text="id"
              item-value="id"
              label="Location in Drawer"
            ></v-autocomplete>
          </v-col>
          <!-- end -->
        </v-row>
      </v-card-text>
    </v-card>
    <snackbar ref="snackbar"></snackbar>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    search: null,
    loading: false,
    drawerAccount: null,
    trueValue: true,
    falseValue: false,
    chartData: [],
    accountData: [],
    chartTree: [],
    levels: [],
    record: {},
    headers: [{ text: "Name", value: "AcctName" }],
         items: [
        {
          id: 1,
          AcctName: "Applications :",
          children: [
            { id: 2, AcctName: "Calendar : app" },
            { id: 3, AcctName: "Chrome : app" },
            { id: 4, AcctName: "Webstorm : app" },
          ],
        },
        {
          id: 5,
          AcctName: "Documents :",
          children: [
            {
              id: 6,
              AcctName: "vuetify :",
              children: [
                {
                  id: 7,
                  AcctName: "src :",
                  children: [
                    { id: 8, AcctName: "index : ts" },
                    { id: 9, AcctName: "bootstrap : ts" },
                  ],
                },
              ],
            },
            {
              id: 10,
              AcctName: "material2 :",
              children: [
                {
                  id: 11,
                  name: "src :",
                  children: [
                    { id: 12, AcctName: "v-btn : ts" },
                    { id: 13, AcctName: "v-card : ts" },
                    { id: 14, AcctName: "v-window : ts" },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: 15,
          AcctName: "Downloads :",
          children: [
            { id: 16, AcctName: "October : pdf" },
            { id: 17, AcctName: "November : pdf" },
            { id: 18, AcctName: "Tutorial : html" },
          ],
        },
        {
          id: 19,
          AcctName: "Videos :",
          children: [
            {
              id: 20,
              AcctName: "Tutorials :",
              children: [
                { id: 21, AcctName: "Basic layouts : mp4" },
                { id: 22, AcctName: "Advanced techniques : mp4" },
                { id: 23, AcctName: "All about app : dir" },
              ],
            },
            { id: 24, AcctName: "Intro : mov" },
            { id: 25, AcctName: "Conference introduction : avi" },
          ],
        },
      ],
  }),
  methods: {
    selectItem(item) {
      this.record = item;
    },
    getChartOfAccounts() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/chartofaccounts`)
        .then((res) => {
          self.chartData = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getAccounts(id) {
      const self = this;
      this.$store
        .dispatch("get", `/chartofaccounts/${id}`)
        .then((res) => {
          self.accountData = res.data;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    setAssetsAccounts() {
      const self = this;
      this.$store
        .dispatch("get", `/chartofaccounts/1`)
        .then((res) => {
          self.accountData = res.data;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getChartOfAccounts();
    this.setAssetsAccounts();
  },
};
</script>